import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_d = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - D'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>D</h2>
     <LatQuotesIntro />
    
     <p><b>Damnum sine injuria</b> - damage without legal injury.</p>
<p><b>De bonis asportatis</b> - Of goods carried away.</p>
<p><b>De bonis non administratis</b> - Of goods not administered.</p>
<p><b>De die in diem</b> - From day to day.</p>
<p><b>De facto</b> - In fact.</p>
<p><b>De futuro</b> - In the future.</p>
<p><b>De integro</b> - As regards the whole.</p>
<p><b>De jure</b> - Rightful, by right.</p>
<p><b>De minimis lex non curat</b> - The law does not notice trifling matters.</p>
<p><b>De novo</b> - Starting afresh.</p>
<p><b>Debile fundamentum fallit opus</b> - Where there is a weak foundation, the work fails.</p>
<p><b>Debita sequuntur personam debitoria</b> - Debts follow the person of the debtor.</p>
<p><b>Debitor non praesumitur donare</b> - A debtor is not presumed to make a gift.</p>
<p><b>Debitum et contractus sunt nullius loci</b> - Debt and contract are of no particular place.</p>
<p><b>Debitum in praesenti, solvendum in futuro</b> - A present debt is to be discharged in the future.</p>
<p><b>Delegata potestas non potest delegari</b> - A delegated authority cannot be again delegated.</p>
<p><b>Derivativa potestas non potest esse major primitiva</b> - The power which is derived cannot be greater than that from which it is derived.</p>
<p><b>Deus solus haeredem facere potest, non homo</b> - God alone, not man, can make an heir.</p>
<p><b>Dies Dominicus non est juridicus</b> - Sunday is not a day in law.</p>
<p><b>Discretio est discernere per legem quid sit justum</b> - Discretion is to discern through law what is just.</p>
<p><b>Doli incapax</b> - Incapable of crime.</p>
<p><b>Dominium</b> - Ownership.</p>
<p><b>Domus sua cuique est tutissimum refugium</b> - Every man s house is his safest refuge.</p>
<p><b>Dona clandestina sunt semper suspiciosa</b> - Clandestine gifts are always suspicious.</p>
<p><b>Dormiunt leges aliquando, nunquam moriuntur</b> - The laws sometimes sleep, but never die.</p>
<p><b>Doti lex favet; praemium pudoris est; ideo parcatur</b> - The law favors dower; it is the reward of chastity, therefore let it be preserved.</p>
<p><b>Dubitante</b> - Doubting the correctness of the decision.</p>
<p><b>Duo non possunt in solido unam rem possidere</b> - Two cannot possess one thing each in entirety.</p>

   </Layout>
  )
}

export default LatQuotes_d
